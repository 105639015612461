<template>
  <div>
    <div class="d-flex mb-8 justify-space-between">
      <div class="titulo-pagina">
        {{ $t('modulos.parametros.titulo') }}
      </div>
      <input-text
        disabled
        sem-label
        :value="valueInputConta"
      />
    </div>
    <v-tabs
      v-model="indiceAba"
      class="abas-padrao"
      background-color="primary"
      color="primary"
    >
      <v-tab> {{ $t('modulos.parametros.parametros_gerais') }} </v-tab>
      <v-tab>
        {{ $t('modulos.parametros.abas.email') }}
      </v-tab>
      <v-tab>
        {{ $t('modulos.parametros.abas.acesso') }}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="indiceAba">
      <v-tab-item v-if="buscarPermissao('Parametro', 'Editar')">
        <v-form ref="form">
          <div class="row mt-2">
            <input-text
              v-model="form.prazoEntregaPadrao"
              type="number"
              class="col-12 col-md-3"
              :regras-personalizadas="[regraNumberNegativo]"
              :label="$t('modulos.parametros.formulario.prazoEntregaPadrao')"
            />
            <input-select
              v-model="form.flagConsiderarDiasUteisPrazoEntrega"
              class="col-12 col-md-3"
              :options="opcoes.booleano"
              :label="
                $t(
                  'modulos.parametros.formulario.flagConsiderarDiasUteisPrazoEntrega'
                )
              "
            />
            <input-text
              v-model="form.prazoGarantia"
              type="number"
              class="col-12 col-md-3"
              :regras-personalizadas="[regraNumberNegativo]"
              :label="$t('modulos.parametros.formulario.prazoGarantia')"
            />
            <input-text
              v-model="form.prazoOrcamentoContratoPadrao"
              type="number"
              class="col-12 col-md-3"
              :regras-personalizadas="[regraNumberNegativo]"
              :label="
                $t('modulos.parametros.formulario.prazoOrcamentoContratoPadrao')
              "
            />
            <input-text
              v-model="form.prazoAgendamentosClientes"
              type="number"
              class="col-12 col-md-3"
              :regras-personalizadas="[regraNumberNegativo]"
              :label="
                $t('modulos.parametros.formulario.prazoAgendamentosClientes')
              "
            />
            <input-text
              v-model="form.prazoConfirmacaoAgendamento"
              type="number"
              class="col-12 col-md-3"
              :regras-personalizadas="[regraNumberNegativo]"
              :label="
                $t('modulos.parametros.formulario.prazoConfirmacaoAgendamento')
              "
            />
            <input-time
              v-model="form.horaMovimentacaoAutomatica"
              class="col-12 col-md-3"
              :label="
                $t('modulos.parametros.formulario.horaMovimentacaoAutomatica')
              "
            />
            <input-text
              v-model="form.pastaArquivosSqlRelatorio"
              class="col-12 col-md-3"
              :label="
                $t('modulos.parametros.formulario.pastaArquivosSqlRelatorio')
              "
            />
          </div>
          <div class="row mt-2 mb-10">
            <input-radio
              v-model="form.flagAtivaIntegracaoOmie"
              class="col-12 col-md-3"
              :divider="false"
              :options="opcoes.booleano"
              :label="
                $t('modulos.parametros.formulario.flagAtivaIntegracaoOmie')
              "
            />
            <input-text
              v-model="form.appKey"
              class="col-12 col-md-3"
              :label="$t('modulos.parametros.formulario.appKey')"
            />
            <input-text
              v-model="form.appSecret"
              class="col-12 col-md-6"
              :label="$t('modulos.parametros.formulario.appSecret')"
            />
          </div>
        </v-form>
      </v-tab-item>
      <v-tab-item v-if="buscarPermissao('Parametro', 'Editar')">
        <v-form
          ref="form-email"
          class="row mt-2 mb-10"
        >
          <input-text
            v-model="form.servidorEmail.usuario"
            class="col-12 col-md-6"
            obrigatorio
            :label="$t('modulos.parametros.formulario.email')"
          />
          <input-text
            v-model="form.servidorEmail.senha"
            class="col-12 col-md-6"
            :label="$t('modulos.parametros.formulario.senha')"
            type="password"
          />
          <input-text
            v-model="form.servidorEmail.servidor"
            class="col-12 col-md-3"
            :label="$t('modulos.parametros.formulario.endereco')"
          />
          <input-text
            v-model="form.servidorEmail.porta"
            class="col-6 col-md-3"
            :label="$t('modulos.parametros.formulario.porta')"
          />
          <input-radio
            v-model="form.servidorEmail.flagEnvioUsuarioLogado"
            :divider="false"
            class="col-3"
            :options="opcoes.booleano"
            :label="$t('modulos.parametros.formulario.envio_usuario_logado')"
          />
        </v-form>
      </v-tab-item>

      <v-tab-item>
        <v-form
          ref="form-acesso"
          class="row mt-2 mb-10"
        >
          <input-radio
            v-model="eFaixaIp"
            :divider="false"
            class="col-3"
            :options="opcoes.booleano"
            :label="$t('modulos.parametros.formulario.range_de_ips')"
          />
          <input-text
            v-model="arrayIP"
            class="col-12 col-md-6"
            :label="
              eFaixaIp
                ? $t(
                  'modulos.parametros.formulario.restricaoAcessoColaboradoresFaixaIP'
                )
                : $t(
                  'modulos.parametros.formulario.restricaoAcessoColaboradoresIP'
                )
            "
            :placeholder="`${
              eFaixaIp ? 'xxx.xxx.xxx.xxx-xxx.xxx.xxx.xxx' : 'xxx.xxx.xxx.xxx'
            }`"
          />
          <botao-padrao
            class="mt-12"
            @click="adicionarRestricaoIP"
          >
            <v-icon>$mdiPlusThick</v-icon>
          </botao-padrao>
          <div class="col-12 col-md-8">
            <label>{{
              $t('modulos.parametros.formulario.ipsSelecionados')
            }}</label>
            <ul
              v-for="(ip, index) in form.parametroGeralEnderecoIPs"
              :key="ip.index"
              class="no-bullets"
            >
              <li>
                {{ ip.enderecoIPInicial }}
                <span v-if="ip.enderecoIPInicial !== ip.enderecoIPFinal">
                  -
                  {{ ip.enderecoIPFinal }}
                </span>
                <button @click.prevent="removerRestricaoIP(ip.id, index)">
                  <v-icon>$mdiTrashCanOutline</v-icon>
                </button>
              </li>
            </ul>
          </div>
        </v-form>
      </v-tab-item>
    </v-tabs-items>
    <template>
      <v-divider class="my-5" />
      <div class="d-flex align-center justify-end">
        <botao-padrao
          v-if="buscarPermissao('Parametro', 'Editar')"
          :disabled="!valido"
          @click="confirmacaoSalvar"
        >
          <v-icon>$mdiContentSaveOutline</v-icon>
          {{ $t('geral.botoes.salvar') }}
        </botao-padrao>
      </div>
    </template>
  </div>
</template>
<script>
import helpers from '@common/utils/helpers';
import ParametroService from '@common/services/cadastros/ParametroService.js';
import { ParametroModel } from '@common/models/cadastros/ParametroModel.js';

export default {
  data() {
    return {
      indiceAba: 0,
      form: new ParametroModel({}),
      valido: false,
      opcoes: {
        booleano: helpers.BoleanoEnum,
      },
      permissoes: [],
      arrayIP: [],
      eFaixaIp: false,
    };
  },
  computed: {
    valueInputConta() {
      return `${this.$t('modulos.parametros.formulario.conta')}: ${
        this.form.nomeConta
      }`;
    },
  },
  watch: {
    form: {
      handler() {
        if (this.$refs['form']) this.valido = this.$refs['form'].validate();
      },
      deep: true,
    },
  },
  created() {
    helpers.redirecionarSemPermissao(this, 'Parametro', 'Editar');
  },
  mounted() {
    this.buscar();
    this.$store.dispatch(
      'Layout/alterarTituloPagina',
      this.$t('modulos.parametros.titulo')
    );
  },
  methods: {
    buscarPermissao(funcionalidade, funcionalidadeAcao) {
      return helpers.buscarStatePermissoes(
        this,
        funcionalidade,
        funcionalidadeAcao
      );
    },
    buscar: function () {
      this.$store.dispatch('Layout/iniciarCarregamento');
      ParametroService.buscar()
        .then((res) => {
          this.form = new ParametroModel(res.data);
          this.valido = true;
        })
        .catch(() => {
          this.toastError(this.$t('modulos.parametros.erros.conta_invalida'));
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    confirmacaoSalvar: function () {
      this.confirmarSalvar().then(() => {
        this.salvar();
      });
    },
    regraNumberNegativo: function (v) {
      if (v < 0)
        return this.$t('modulos.parametros.validacoes.valor_minimo_zero');
      return true;
    },
    regraIPValido: function (ip) {
      const ipv4Single = /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
      const ipv4Range = /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\s*-\s*((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
      const ipv6Single = /^((?:[A-F0-9]{1,4}:){7}[A-F0-9]{1,4})$/i;
      const ipv6Range = /^((?:[A-F0-9]{1,4}:){7}[A-F0-9]{1,4})\s*-\s*((?:[A-F0-9]{1,4}:){7}[A-F0-9]{1,4})$/i;

      return ipv4Single.test(ip) || ipv4Range.test(ip) || ipv6Single.test(ip) || ipv6Range.test(ip);
    },
    salvar: function () {
      if (!this.valido) return;
      this.$store.dispatch('Layout/iniciarCarregamento');
      ParametroService.salvar(this.form.request)
        .then(() => {
          this.toastSucesso(this.$t(`modulos.parametros.cadastro_sucesso`));
          this.$router.push({ name: 'dashboard' });
        })
        .catch((err) => {
          this.toastErro(this.$t(`geral.erros.${err.response.data.errors[0]}`));
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    adicionarRestricaoIP: function () {
      try {
        if (!this.regraIPValido(this.arrayIP)) throw new Error();

        let ipInicial, ipFinal;

        if (this.eFaixaIp && this.arrayIP.includes('-')) {
          [ipInicial, ipFinal] = this.arrayIP.split('-').map(ip => ip.trim());
        } else {
          if (this.arrayIP.includes('-')) throw new Error();
          ipInicial = this.arrayIP.trim();
          ipFinal = ipInicial;
        }

        const novaRestricao = {
          id: null,
          enderecoIPInicial: ipInicial,
          enderecoIPFinal: ipFinal,
        };

        this.form.parametroGeralEnderecoIPs.push(novaRestricao);
        this.arrayIP = '';
      } catch (error) {
        if (this.eFaixaIp)
          return this.toastErro(this.$t('modulos.parametros.validacoes.ip_range_invalido'));

        this.toastErro(this.$t('modulos.parametros.validacoes.ip_invalido'));
      }
    },

    removerRestricaoIP: function (ipId, index) {
      if (
        !ipId &&
        index >= 0 &&
        index < this.form.parametroGeralEnderecoIPs.length
      ) {
        this.form.parametroGeralEnderecoIPs =
          this.form.parametroGeralEnderecoIPs.filter(
            (el, idx) => idx !== index
          );
      } else {
        this.form.parametroGeralEnderecoIPs =
          this.form.parametroGeralEnderecoIPs.filter((el) => el.id !== ipId);
      }
    },
  },
};
</script>
<style scoped>
.ipBox {
  border: 5px solid #35507b;
  border-radius: 5px;
  padding: 10px;
  margin: 10px;
  margin-left: 30px;
  width: 100%;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  background-color: #f5f5f5;
}
.no-bullets {
  list-style: none;
  margin: 0;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-inline-start: 0;
}
</style>
