import _ from 'lodash';

export class ParametroModel {
  constructor({
    nomeConta,
    prazoEntregaPadrao,
    flagConsiderarDiasUteisPrazoEntrega,
    prazoGarantia,
    prazoOrcamentoContratoPadrao,
    flagAtivaIntegracaoOmie = false,
    appKey,
    appSecret,
    servidorEmail = {},
    prazoAgendamentosClientes = '',
    prazoConfirmacaoAgendamento = '',
    parametroGeralEnderecoIPs =
    [
      {
        id: null,
        enderecoIPInicial: '',
        enderecoIPFinal: ''
      }
    ],
    horaMovimentacaoAutomatica = '',
    pastaArquivosSqlRelatorio = ''
  }) {
    this.nomeConta = nomeConta;
    this.prazoEntregaPadrao = prazoEntregaPadrao;
    this.flagConsiderarDiasUteisPrazoEntrega =
      flagConsiderarDiasUteisPrazoEntrega;
    this.prazoGarantia = prazoGarantia;
    this.prazoOrcamentoContratoPadrao = prazoOrcamentoContratoPadrao;
    this.flagAtivaIntegracaoOmie = flagAtivaIntegracaoOmie
    this.appKey = appKey;
    this.appSecret = appSecret;
    this.servidorEmail = servidorEmail;
    this.prazoAgendamentosClientes = prazoAgendamentosClientes,
      this.prazoConfirmacaoAgendamento = prazoConfirmacaoAgendamento
    this.parametroGeralEnderecoIPs = parametroGeralEnderecoIPs
    this.horaMovimentacaoAutomatica = horaMovimentacaoAutomatica
    this.pastaArquivosSqlRelatorio = pastaArquivosSqlRelatorio
  }
  get request() {
    const retorno = _.cloneDeep(this);
    Object.keys(retorno).forEach((key) => {
      if (!retorno[key]) retorno[key] = 0;
    });
    if (retorno.horaMovimentacaoAutomatica == 0)
      delete retorno.horaMovimentacaoAutomatica;
    return retorno;
  }
}
